import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Slider from 'react-slick'
import { Accordion, Card } from 'react-bootstrap'
import { Layout, SEO, Jumbotron } from '../components'

const Index = ({ data }) => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    centerMode: true,
    centerPadding: '24px',
    arrows: false,
    swipeToSlide: true,
    responsive: [{
      breakpoint: 737,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  }

  return (
    <Layout>
      <SEO />
      <Jumbotron />

      <section className="container intro pt-5">
        <div className="row">
          <div className="col-md-8 col-lg-5">
            <h3 className="text-uppercase font-italic text-primary text-right mb-4">Malaysia's <span className="h2 font-weight-bold">first & leading </span><br className="d-none d-md-block" />construction time-lapse provider</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 offset-md-2 col-lg-6 offset-lg-6">
            <h3 className="text-uppercase font-italic text-primary text-right"><span className="h2 font-weight-bold">1,000,000+ site photographs captured </span><br className="d-none d-md-block" />over 35 projects nationwide</h3>
          </div>
        </div>
      </section>

      <section className="container story pt-5" id="story">
        <div className="row">
          <div className="col-md-6 col-lg-5 order-md-2 my-auto">
            <div className="card border-0 bg-transparent">
              <div className="card-header px-0">
                <small className="text-uppercase">Our story</small>
              </div>
              <div className="card-body pt-0 px-0">
                <h3 className="card-title text-primary text-uppercase">Advancing Construction Chronology</h3>
                <p className="lead">As Malaysia's first and leading provider of time-lapse construction image capture, TIME LAPSE remains at the industry forefront with over 1 million site progress photographs captured, covering more than 35 projects around the country.</p>
                <p>CIDB- and BOSIET-certified, TIME LAPSE has a proven track record in delivering best-in-class service in various sectors including residential, commercial, infrastructure and oil & gas.</p>
                <p className="mb-0">Utilising the latest photo- and video-capture technology as well as 24/7 cloud services, we enable clients and stakeholders to monitor and oversee the entire construction process from start to completion, in superior image quality and in real-time.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-7 order-md-1 my-auto pl-md-0">
            <Img
              className="img-fluid rounded shadow"
              fluid={data.story.childImageSharp.fluid}
              alt="Exchange 106 crown taken from our drone"
            />
          </div>
        </div>
      </section>

      <section className="container py-5" id="solutions">
        <div className="row">
          <div className="col-md-6 col-lg-5 my-auto">
            <div className="card border-0 bg-transparent">
              <div className="card-header px-0">
                <small className="text-uppercase">Our solutions</small>
              </div>
              <div className="card-body pt-0 px-0">
              <h3 className="card-title mb-4">Why TIME LAPSE?</h3>
              <ul className="list-unstyled">
                <li className="media mb-4">
                  <i className="material-icons my-auto mr-3">camera</i>
                  <div className="media-body">
                    Capture & archive the entire construction process from start to finish, with <span className="font-weight-bold">over 46,000 photos captured over a 2-year project</span>
                  </div>
                </li>
                <li className="media mb-4">
                  <i className="material-icons my-auto mr-3">linked_camera</i>
                  <div className="media-body">
                    Monitor & review construction progress in real time <span className="font-weight-bold">from anywhere in the world, 24/7</span> (direct-to-power system)
                  </div>
                </li>
                <li className="media mb-4">
                  <i className="material-icons my-auto mr-3">phonelink</i>
                  <div className="media-body">
                    <span className="font-weight-bold">Improve communication & eliminate confusion</span> between stakeholders, project team and contractor
                  </div>
                </li>
                <li className="media mb-4">
                  <i className="material-icons my-auto mr-3">wallpaper</i>
                  <div className="media-body">
                    <span className="font-weight-bold">Cross-check payment claims & resolve disputes</span> through visual reporting 
                  </div>
                </li>
                <li className="media mb-4">
                  <i className="material-icons my-auto mr-3">security</i>
                  <div className="media-body">
                    Protect your investment & reduce risk with <span className="font-weight-bold">a comprehensive legal record</span> of time-stamped and geo-specific photographic evidence
                  </div>
                </li>
              </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-7 my-auto pr-md-0">
            <span className="corner-triangle"></span>
            <Img
              className="img-fluid rounded shadow"
              fluid={data.solution.childImageSharp.fluid}
              alt="MRT under construction"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid services py-5" id="services">
        <div className="container py-5 px-0">
          <div className="row">
            <div className="col-md-7 order-md-2 pr-md-5">
              <Accordion>
                <Card>
                  <Card.Header>
                    <small className="text-uppercase">Our services</small>
                  </Card.Header>
                </Card>
                <Card className="border-top-0">
                  <Accordion.Toggle as={Card.Header} eventKey="0" className="media list-group-item-action collapsed">
                    <div className="media-body">
                      <h4 className="font-weight-bold">Construction time-lapse monitoring system</h4>
                      <p className="mb-1">Capture the full construction cycle beginning to end.</p>
                    </div>
                    <i className="material-icons">more_vert</i>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p>With construction efficiency increasing, so does works on site rapidly progress. Monitoring of the works is suddenly critical, which is why time-lapse photography has become an indispensable tool to projects worldwide. Capture progress on your site 24/7 and track all images online via a dedicated project website. At the end of each month, images will compiled as a high-quality site progress video report.</p>

                      <p className="mb-0">Our system includes:</p>
                      <ul>
                        <li>24MP photograps + 4K video output</li>
                        <li>Wireless 4G camera connectivity on site</li>
                        <li>Solar powered for sustainability</li>
                        <li>Dedicated online project website</li>
                        <li>Robust website with download/export capabilities</li>
                        <li>International editing standard (stringent filtering)</li>
                        <li>Full-system maintenance throughout construction cycle</li>
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                
                <Card className="border-top-0">
                  <Accordion.Toggle as={Card.Header} eventKey="1" className="media list-group-item-action collapsed">
                    <div className="media-body">
                      <h4 className="font-weight-bold">Site progress video reporting</h4>
                      <p className="mb-1">The new way to report summarised works carried out at the site.</p>
                    </div>
                    <i className="material-icons">more_vert</i>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p>With our team of videographer, photographer and drone pilot, TIMELAPSE captures the latest site progress & project milestones every month. We cover areas such as construction zones and built layouts, construction activities as well as aerial views of the site. All images and footage are professional compiled and edited to creates a 2-3 minute highlights that showcases the past month's progress.</p>
                      <ul>
                        <li>Monthly site progress video report to stakeholders</li>
                        <li>CIDB-certified team with essential construction knowledge deployed to site</li>
                        <li>4K ground + aerial footage</li>
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card className="border-top-0">
                  <Accordion.Toggle as={Card.Header} eventKey="2" className="media list-group-item-action collapsed">
                    <div className="media-body">
                      <h4 className="font-weight-bold">Project documentary & corporate video</h4>
                      <p className="mb-1">Scripting & presenting the construction chronology as a broadcast-quality documentary.</p>
                    </div>
                    <i className="material-icons">more_vert</i>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <p>Recreate the life of a project through our broadcast-quality video documentary. From inception to completion, we capture and narrate the construction chronology, using extensive footage taken via time lapse and site progress videos on top of specific scripted and filmed scenes to enhance production value.</p>

                      <p>Through storyboards, scripts, voice-overs, interviews with key personnel plus other production tools, the project documentary becomes an essential medium that, more than just video, becomes a reliable and marketable asset for corporate use, branding, promotions, project referencing, training and more.</p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
            <div className="d-none d-md-block col-md-5 order-md-1">
              &nbsp;
            </div>
          </div>
        </div>
      </section>

      <section className="container pt-5" id="projects">
        <div className="card text-center border-0 bg-transparent">
          <div className="card-header px-0">
            <small className="text-uppercase">Featured projects</small>
          </div>
          <div className="card-body p-0 mb-0">
            <h3 className="card-title">The projects speak volumes about us.</h3>
            <p className="lead">We work with some of the most successful companies from Malaysia.</p>
          </div>
        </div>
      </section>

      <section className="container-fluid overflow-hidden px-0 pb-5">
        <Slider {...settings}>
          <div className="mb-5 px-2">
            <div className="card border-0 shadow h-100">
              <div className="row no-gutters rounded overflow-hidden">
                <div className="col-md-4">
                  <Img
                    className="img-fluid"
                    fluid={data.exchange106.childImageSharp.fluid}
                    alt="Exchange 106 crown taken from our drone"
                    style={{ height: '100%', width: 'auto' }}
                    imgStyle={{ objectFit: 'cover', objectPosition: 'left bottom' }}
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-header">
                    <small className="text-uppercase">Malaysia's tallest building</small>
                  </div>
                  <div className="card-body pt-0">
                    <h3 className="card-title text-uppercase">The Exchange 106</h3>
                    <p className="text-primary">
                      492 metres high<br />
                      106 levels & 6 basements<br />
                      240,000 sqm nett lettable area
                    </p>
                    <p className="mb-0">At the heart of the Tun Razak Exchange (TRX) development is the centrepiece Exchange 106, a landmark that has already surpassed the Petronas Twin Towers as the tallest building in the country. At 106 storeys, the modern icon is nestled in a park setting and is designed for to sustainable specifications and standards, with certification under the Green Building Index (GBI) and Leadership in Energy and Environmental Design (LEED).</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-5 px-2">
            <div className="card border-0 shadow h-100">
              <div className="row no-gutters rounded overflow-hidden">
                <div className="col-md-4">
                  <Img
                    className="img-fluid"
                    fluid={data.trx.childImageSharp.fluid}
                    alt="Tun Razak Exchange"
                    style={{ height: '100%', width: 'auto' }}
                    imgStyle={{ objectFit: 'cover', objectPosition: 'left bottom' }}
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-header">
                    <small className="text-uppercase">Kuala Lumpur's new future-ready CBD</small>
                  </div>
                  <div className="card-body pt-0">
                    <h3 className="card-title text-uppercase">Tun Razak Exchange</h3>
                    <p className="text-primary">
                      70 acres with GDV of MYR40 billion<br />
                      24 million sqft GFA<br />
                      30 buildings on site
                    </p>
                    <p className="mb-0">Excellent connectivity, iconic public realms and smart and sustainable design come together to create Malaysia's first international financial district at Tun Razak Exchange (TRX). Designed as KL's best-connected address, TRX's 70-acre masterplan includes a dedicated MRT interchange station and direct accesses to Jalan Tun Razak, MEX and SMART Highways, as well as the upcoming DUKE 3 Highway.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-5 px-2">
            <div className="card border-0 shadow h-100">
              <div className="row no-gutters rounded overflow-hidden">
                <div className="col-md-4">
                  <Img
                    className="img-fluid"
                    fluid={data.ophir.childImageSharp.fluid}
                    alt="Ophir Wellhead Platform"
                    style={{ height: '100%', width: 'auto' }}
                    imgStyle={{ objectFit: 'cover', objectPosition: 'left bottom' }}
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-header">
                    <small className="text-uppercase">World's first offshore platform relocation via suction pile method</small>
                  </div>
                  <div className="card-body pt-0">
                    <h3 className="card-title text-uppercase">Ophir Wellhead Platform</h3>
                    <p className="text-primary">
                      1,100-tonne jacket with 350-tonne topside<br />
                      14-metre deep suction piles<br />
                      Water depth at 73 metres
                    </p>
                    <p className="mb-0">Situated 200km of the Terengganu coastline, the Ophir wellhead platform is the first of its kind to feature a tri-legged acket with integrated suction pile foundation, making the offshore installation safe and swift in a record breaking 72 hours.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-5 px-2">
            <div className="card border-0 shadow h-100">
              <div className="row no-gutters rounded overflow-hidden">
                <div className="col-md-4">
                  <Img
                    className="img-fluid"
                    fluid={data.mrt2.childImageSharp.fluid}
                    alt="MRT2 Line 2 Underground"
                    style={{ height: '100%', width: 'auto' }}
                    imgStyle={{ objectFit: 'cover', objectPosition: 'left bottom' }}
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-header">
                    <small className="text-uppercase">Commuting half-a-million people daily</small>
                  </div>
                  <div className="card-body pt-0">
                    <h3 className="card-title text-uppercase">MRT2 Line 2 Underground</h3>
                    <p className="text-primary">
                      11 underground stations over 13.5km<br />
                      MYR13 billion construction cost
                    </p>
                    <p className="mb-0">The SSP Line is the second MRT line being developed, serving a corridor stretching from Sungai Buloh to Putrajaya that includes Jalan Tun Razak, KLCC, Tun Razak Exchange and Cyberjaya, among others. At commencement of full service in the second quarter of 2022, the SSP Line is expected to have a ridership of 529,000 passengers per day.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-5 px-2">
            <div className="card border-0 shadow h-100">
              <div className="row no-gutters rounded overflow-hidden">
                <div className="col-md-4">
                  <Img
                    className="img-fluid"
                    fluid={data.ikea.childImageSharp.fluid}
                    alt="IKEA South-East Asia"
                    style={{ height: '100%', width: 'auto' }}
                    imgStyle={{ objectFit: 'cover', objectPosition: 'left bottom' }}
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-header">
                    <small className="text-uppercase">Swedish charm grows nationwide</small>
                  </div>
                  <div className="card-body pt-0">
                    <h3 className="card-title text-uppercase">IKEA south-east asia projects</h3>
                    <p className="text-primary">
                      2 new developments in Penang and Johor<br />
                      Redevelopment of flagship property<br />
                      1.4 million sqft of commercial area
                    </p>
                    <p className="mb-0">The Swedish furniture brand continues to strengthen in Malaysia through its latest stores in IKEA Batu Kawan, Penang and IKEA Tebrau + Toppen Shopping Centre, Johor, with the latter expecting to attract 15 million visitors by 2020 including those living in Singapore. In addition, the IPC Shopping Centre that connects to flagship store IKEA Damansara has undergone a major facelift that showcases 145 stores of existing and new tenants, revitalised with a Scandinavian-inspired interior.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </section>

      <section className="container-fluid clientelle pt-5 px-0" id="clientelle">
        <div className="container">
          <div className="card text-center border-0 bg-transparent">
            <div className="card-header px-0">
              <small className="text-uppercase">Our clientelle</small>
            </div>
            <div className="card-body p-0 mb-0">
              <h3 className="card-title">We strive to work with the best.</h3>
              <p className="lead">Time Lapse is blessed to work with some of the greatest companies in the world.</p>
            </div>
          </div>
        </div>
        <Img
          className="d-none d-md-block img-fluid w-100 h-100"
          fluid={data.client.childImageSharp.fluid}
          alt="Our clientele of magnificent companies"
        />
        <Img
          className="d-block d-md-none img-fluid w-100 h-100"
          fluid={data.clientmobile.childImageSharp.fluid}
          alt="Our clientele of magnificent companies"
        />
      </section>

      <section className="container-fluid bg-dark text-light">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <i className="material-icons md-36 mb-2">camera_enhance</i>
              <h3 className="font-weight-bold mb-1">
                A better way to capture
              </h3>
              <p className="lead mb-5 mb-md-0">
                Get your stories delivered visually.
              </p>
            </div>
            <div className="col-12 col-md-auto">
              <a href="/contact/" className="btn btn-lg btn-primary">
                Contact us
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query {
    story: file(relativePath: {eq: "index-story.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 650) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    solution: file(relativePath: {eq: "index-solution.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 650) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    client: file(relativePath: {eq: "index-client.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    clientmobile: file(relativePath: {eq: "index-client-mobile.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exchange106: file(relativePath: {eq: "project-exchange106.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    trx: file(relativePath: {eq: "project-trx.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ophir: file(relativePath: {eq: "project-ophir.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mrt2: file(relativePath: {eq: "project-mrt2.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ikea: file(relativePath: {eq: "project-ikea.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`